<template>
    <div>
        <div>
            <Breadcrumb content="修改密码"/>
        </div>
        <div style="width: 50%">
            <el-form ref="form" :model="form" label-width="80px" :rules="rules">
                <el-form-item label="原密码" prop="oldPass">
                    <el-input type="password" v-model="form.oldPass"></el-input>
                </el-form-item>
                <el-form-item label="新密码" prop="pass">
                    <el-input type="password" v-model="form.pass"></el-input>
                </el-form-item>
                <el-form-item label="再次输入" prop="password_confirmation">
                    <el-input type="password" v-model="form.password_confirmation"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">立即修改</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
    import Breadcrumb from '@/components/admin/breadcrumb'
    export default {
        components:{Breadcrumb},
        name: "index",
        data:function () {
            return {
                form:{
                    oldPass:"",
                    pass:"",
                    password_confirmation:""
                },
                rules:{
                    oldPass:[
                        {required:true,message:'原密码不能为空', trigger: 'blur' },
                    ],
                    pass:[
                        {required:true,message:'新密码不能为空', trigger: 'blur' },
                    ],
                    password_confirmation:[
                        {required:true,message:'新密码不能为空', trigger: 'blur' },
                    ]
                },
            }
        },
        methods:{
            onSubmit:function () {
                this.$refs['form'].validate((valid) => {
                    if (valid === false) {
                        this.$message.error("参数错误");
                        return false;
                    }
                    if (this.form.pass == this.form.oldPass){
                        this.$message.error("不能和原密码一样");
                        return false;
                    }
                    if (this.form.pass != this.form.password_confirmation){
                        this.$message.error("两次密码不一致");
                        return false;
                    }
                    this.modify();
                });
            },
            modify:function () {
                let oldPass = this.form.oldPass;
                let pass = this.form.pass;
                let pass_confirmation = this.form.password_confirmation;

                this.$axios.post("/site/user/resetPass",{oldPass,pass,pass_confirmation})
                    .then((res)=>{
                        if (res.data.code != 1000){
                            this.$message.error(res.data.msg);
                            return;
                        }else if (res.data.code == 1000) {
                            this.$message.success("修改成功");
                        }
                    })
            }
        }
    }
</script>

<style scoped>

</style>
